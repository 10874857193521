import { Oasis } from '@oasis/sdk';
import { useEffect } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { proxyMap } from 'valtio/utils';
import { z } from 'zod';

const viewerUserSchema = z.object({
  deviceId: z.string(),
  isLocal: z.boolean(),
  isOwner: z.boolean().optional(),
  name: z.string().optional(),
  isMuted: z.boolean().optional(),
  deviceType: z.string().optional(),
  canMuteOthers: z.boolean().optional(),
  canGatherOthers: z.boolean().optional(),
  canBeGathered: z.boolean().optional(),
  canBeGotoed: z.boolean().optional(),
  // profileImageUrl: z.string().optional(), -- we get this elsewhere for now
});

export type WorkshopPeopleLpcUserAttrs = z.infer<typeof viewerUserSchema>;
export type WorkshopPeopleLpcUser = Required<WorkshopPeopleLpcUserAttrs>;

const unrealToConsoleSchema = z.object({
  type: z.union([z.literal('AddUser'), z.literal('UpdateUser'), z.literal('RemoveUser')]),
  attrs: viewerUserSchema,
});

export const WorkshopPeopleLpc = {
  store: proxy({
    users: proxyMap<string, WorkshopPeopleLpcUser>(),
  }),

  useStore(opts?: { resetOnUnmount?: boolean }) {
    useEffect(() => {
      return () => {
        if (opts?.resetOnUnmount) {
          WorkshopPeopleLpc.store.users.clear();
        }
      };
    }, [opts?.resetOnUnmount]);

    return useSnapshot(this.store);
  },

  listen() {
    document.addEventListener('unreal-to-console', this.handleUnrealMessage);
  },

  unlisten() {
    document.removeEventListener('unreal-to-console', this.handleUnrealMessage);
  },

  handleUnrealMessage(event: Event) {
    try {
      Oasis.Logger.debug({ event, msg: '[WorkshopPeopleLpc] Received event from Unreal' });

      if (event instanceof CustomEvent) {
        const data = unrealToConsoleSchema.parse(event.detail);

        Oasis.Logger.debug({ data, msg: '[WorkshopPeopleLpc] Parsed event from Unreal' });

        switch (data.type) {
          case 'AddUser':
            this.addUser(data.attrs);
            break;
          case 'UpdateUser':
            this.updateUser(data.attrs.deviceId, data.attrs);
            break;
          case 'RemoveUser':
            this.removeUser(data.attrs.deviceId);
            break;
          default:
            data.type satisfies never;
        }
      }
    } catch (error) {
      Oasis.Logger.error({ error, msg: '[WorkshopPeopleLpc] Failed to parse event details' });
    }
  },

  createUserObject(
    current: WorkshopPeopleLpcUserAttrs,
    next?: WorkshopPeopleLpcUserAttrs
  ): WorkshopPeopleLpcUser {
    return {
      deviceId: current.deviceId,
      isLocal: current.isLocal,
      isOwner: current.isOwner ?? false,
      name: next?.name ?? current.name ?? 'Anonymous',
      deviceType: next?.deviceType ?? current.deviceType ?? 'Unknown',
      isMuted: next?.isMuted ?? current.isMuted ?? false,
      canMuteOthers: next?.canMuteOthers ?? current.canMuteOthers ?? false,
      canGatherOthers: next?.canGatherOthers ?? current.canGatherOthers ?? false,
      canBeGathered: next?.canBeGathered ?? current.canBeGathered ?? false,
      canBeGotoed: next?.canBeGotoed ?? current.canBeGotoed ?? false,
    };
  },

  addUser(attrs: WorkshopPeopleLpcUserAttrs) {
    this.store.users.set(attrs.deviceId, this.createUserObject(attrs));
  },

  updateUser(id: string, attrs: WorkshopPeopleLpcUserAttrs) {
    const existing = this.store.users.get(id);

    // When unreal is adding a new primary user reset the list
    if (attrs.isLocal) {
      this.store.users.clear();
    }

    if (existing) {
      const updated = this.createUserObject(existing, attrs);
      this.store.users.set(id, updated);
    }
  },

  removeUser(id: string) {
    this.store.users.delete(id);
  },

  mute(deviceId: string) {
    this.emitViewerEvent('muteUser', JSON.stringify({ target: deviceId }));
  },

  unmute(deviceId: string) {
    this.emitViewerEvent('unmuteUser', JSON.stringify({ target: deviceId }));
  },

  muteAll() {
    this.emitViewerEvent('muteAll', JSON.stringify({}));
  },

  gather(deviceId: string) {
    this.emitViewerEvent('gatherUser', JSON.stringify({ target: deviceId }));
  },

  goToUser(deviceId: string) {
    this.emitViewerEvent('goToUser', JSON.stringify({ target: deviceId }));
  },

  gatherAll() {
    this.emitViewerEvent('gatherAll', JSON.stringify({}));
  },

  emitViewerEvent(event: unknown, json: unknown) {
    const ue = (window as any).ue;

    if (ue) {
      ue.javascripteventhandler.handleevent(event, json);
    } else {
      Oasis.Logger.error({ event, json, msg: '[WorkshopPeopleLpc] Could not send UE message' });
    }
  },
};
