import { Oasis } from '@oasis/sdk';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectContextProvider } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { useFeatureFlags } from '~/shared/hooks/use-feature-flags';
import { landingUrl } from '~/shared/utils/const.asset-urls';
import { ViewingRoom } from './components/viewing-room';
import { WaitingRoom } from './components/waiting-room';
import { WorkshopViewerHeader } from './components/workshop-viewer-header';

export type ViewerPageState = 'WAITING' | 'READY';

export function WorkshopViewerPage() {
  const { projectId, workshopId } = useParams<{ projectId: string; workshopId: string }>();

  if (!projectId || !workshopId) {
    throw new Error('<WorkshopViewerPage /> requires `projectId` and `workshopId` url path params.');
  }

  // const permissions = useWorkshopPermissions(workshopId);

  const showCollaborativeWebViewer = useFeatureFlags('241127-7456-web-viewer');
  const [state, setState] = useState<ViewerPageState>('WAITING');

  const workshop = Queries.Workshops.useFindWorkshopById({ workshopId });
  Oasis.WorkshopPeopleLpc.useStore({ resetOnUnmount: true });

  useEffect(() => {
    Oasis.Session.setActiveWorkshop(workshopId);
    Oasis.Voice.init();
    Oasis.Voice.requestMicTrack();

    return () => {
      Oasis.Session.clearActiveWorkshop();
      Oasis.Voice.disconnect();
    };
  }, [workshopId]);

  // const encryptionMode = workshop?.data?.settings?.voice?.encryptionMode;

  // useEffect(() => {
  //   if (encryptionMode !== 'NONE' && permissions.canManage) {
  //     Oasis.Workshops.updateWorkshop({
  //       workshopId,
  //       attrs: {
  //         settings: {
  //           voice: {
  //             encryptionMode: 'NONE',
  //           },
  //         },
  //       },
  //     });
  //   }
  // }, [workshopId, encryptionMode, permissions.canManage]);

  if (!showCollaborativeWebViewer) {
    return null;
  }

  return (
    <ProjectContextProvider projectId={projectId}>
      <section className="min-w-screen min-h-screen flex flex-col">
        <WorkshopViewerHeader projectId={projectId} workshop={workshop} viewerPageState={state} />

        <div className="flex flex-1 bg-cover" style={{ backgroundImage: `url(${landingUrl})` }}>
          {state === 'WAITING' && (
            <WaitingRoom
              projectId={projectId}
              workshopId={workshopId}
              workshop={workshop}
              setViewerPageState={setState}
            />
          )}

          {state === 'READY' && (
            <ViewingRoom
              projectId={projectId}
              workshopId={workshopId}
              workshop={workshop}
              setViewerPageState={setState}
            />
          )}
        </div>
      </section>
    </ProjectContextProvider>
  );
}
