import {
  ArrowAlignLeftIcon,
  ArrowAlignRightIcon,
  CheckmarkCircleIcon,
  DocumentEmptyIcon,
  DocumentMagnifyingGlassIcon,
  PeopleIcon,
} from '@adsk/alloy-react-icon';
import { Oasis } from '@oasis/sdk';
import { ArrayUtils } from '@oasis/utils';
import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import { NavLink, Outlet, useMatch, useParams } from 'react-router-dom';
import { ConditionalWrapper } from '~/shared/components/base/conditional-wrapper';
import { Tooltip } from '~/shared/components/base/tooltip';
import { ProjectContext } from '~/shared/contexts/project-context';
import { Queries } from '~/shared/hooks/queries';
import { Subscriptions } from '~/shared/hooks/subscriptions';
import { useUI } from '~/shared/hooks/use-ui-store';
import { OasisError } from '~/shared/utils/oasis-error';
import { WorkshopsIcon } from '../../components/icons/workshops-icon';
import { OpenWorkshopOnDesktopModal } from './components/open-workshop-on-desktop-modal';
import { OpenWorkshopOnHeadsetModal } from './components/open-workshop-on-headset-modal';
import { configureAcc } from '~/shared/utils/acc-config';

export default function ProjectLayout() {
  const $env = Oasis.Env.useStore();
  const $session = Oasis.Session.useStore();
  const $ui = useUI();
  const $projects = Oasis.Projects.useStore();
  const { projectId } = useParams();
  const showNav = useNavigationVisibility();

  if (!projectId) {
    throw new OasisError(
      { code: 'BAD_REQUEST', description: 'projectId is a required parameter' },
      '<ProjectLayout />'
    );
  }

  Queries.Users.useListActiveWorkshops(projectId); // poll for active workshops

  const projects = Queries.Projects.useListAllProjects({
    opts: { networkMode: 'offlineFirst' },
  });

  Subscriptions.useIssueEvents(projectId);

  useEffect(() => {
    configureAcc();
  }, []);

  // Persist the current project id to local storage.
  // Track Pendo and Segment events.
  useEffect(() => {
    const activeProject = projects.data?.find(project => project.id === projectId);

    if (activeProject?.accountId) {
      Oasis.Session.setRegion(activeProject.region);
      Oasis.Projects.persistCurrentProjectId(projectId);
      Oasis.Pendo.identify({ account: { id: activeProject.accountId } });
      Oasis.Segment.track('Project Accessed', { id: projectId, product: activeProject.platform });
    }

    return () => {
      Oasis.Storage.removeTemporary('activeFolderUrn');
      Oasis.Projects.forgetCurrentProjectId();
    };
  }, [projectId, projects.data]);

  // Define the `ProjectContext` value.
  const contextValue = useMemo(() => {
    const projectMeta = $projects.metaCache.get(projectId);

    if (projectMeta) {
      return {
        projectId,
        hubId: projectMeta.hubId,
        platform: projectMeta.platform,
        region: projectMeta.region,
      };
    }
  }, [projectId, $projects.metaCache]);

  // Define the left sidebar navigation items.
  const notFreeViewer = $session.license.type !== 'FREE_VIEWER';
  const notBim360 = contextValue?.platform === 'acc';
  const navItems = [
    // The `vr-homespace` hides nav anyways and its the only time this would display.
    $env.isWeb && {
      label: 'Workshops',
      to: `/projects/${projectId}`,
      icon: <WorkshopsIcon />,
    },

    notFreeViewer && {
      label: 'Files',
      to: `/projects/${projectId}/files`,
      icon: <DocumentEmptyIcon />,
    },

    ((notBim360 && notFreeViewer) || $env.isDevMode) && {
      label: 'Issues',
      to: `/projects/${projectId}/issues`,
      icon: <CheckmarkCircleIcon />,
    },

    notFreeViewer && {
      label: 'Members',
      to: `/projects/${projectId}/members`,
      icon: <PeopleIcon />,
    },

    $env.isDevMode &&
      Boolean($session.activeWorkshop) && {
        label: 'Inspector',
        to: `/projects/${projectId}/inspector`,
        icon: <DocumentMagnifyingGlassIcon />,
      },
  ].filter(ArrayUtils.truthy);

  const collapseNav = $ui.collapsedNav || ($env.isVr && $env.isDevMode);

  // We can't determine if the project is `acc` or `bim360` until we get it from the projects list.
  return (
    <>
      <div className="flex flex-1 overflow-hidden">
        {showNav && (
          <nav
            className={clsx(
              'flex flex-col bg-charcoal-50 overflow-hidden',
              collapseNav ? 'min-w-[3.25rem] max-w-[3.25rem]' : 'min-w-[13.75rem]',
              !showNav
                ? $env.isDevMode
                  ? 'fixed z-10 bottom-0 left-0 opacity-0 hover:opacity-100'
                  : 'hidden'
                : undefined
            )}
          >
            {navItems.map(item => {
              return (
                <ConditionalWrapper
                  key={item.label}
                  condition={$env.isVr || $ui.collapsedNav}
                  wrapper={children => (
                    <Tooltip content={item.label} placement="right" delay={0}>
                      {children}
                    </Tooltip>
                  )}
                >
                  <NavLink
                    title={item.label}
                    to={item.to}
                    end
                    className={({ isActive }) =>
                      clsx(
                        'flex items-center h-12 text-lg border-l-2 cursor-pointer pl-3',
                        isActive
                          ? 'bg-white text-blue-500 border-blue-500'
                          : 'text-charcoal-700 hover:text-blue-500 border-transparent'
                      )
                    }
                  >
                    <div className={clsx(!$env.isVr && 'mr-3')}>{item.icon}</div>
                    {!collapseNav && item.label}
                  </NavLink>
                </ConditionalWrapper>
              );
            })}

            {!$env.isVr && (
              <button
                onClick={() => {
                  $ui.collapsedNav = !$ui.collapsedNav;
                }}
                className="mt-auto ml-1 mb-2 rounded hover:bg-charcoal-100 self-start"
              >
                <Tooltip placement="right" content={$ui.collapsedNav ? 'Expand' : 'Collapse'}>
                  <div className="p-2">
                    {$ui.collapsedNav ? <ArrowAlignRightIcon /> : <ArrowAlignLeftIcon />}
                  </div>
                </Tooltip>
              </button>
            )}
          </nav>
        )}

        {contextValue && (
          <ProjectContext.Provider value={contextValue}>
            <Outlet />
          </ProjectContext.Provider>
        )}
      </div>

      <OpenWorkshopOnDesktopModal projectId={projectId} />
      <OpenWorkshopOnHeadsetModal />
    </>
  );
}

/**
 * Hook to determine if the navigation should be visible.
 * Hide it when:
 * - Viewing a document
 * - Viewing a workshop in viewer mode
 */
function useNavigationVisibility() {
  const $env = Oasis.Env.useStore();
  const docMatch = useMatch('/projects/:projectId/files/:documentId');
  const viewerMatch = useMatch('/projects/:projectId/workshops/:workshopId/viewer');

  const isDocumentViewer = docMatch?.params.documentId;
  const isWorkshopViewer = viewerMatch?.pathname.includes('viewer');

  return !isDocumentViewer && !isWorkshopViewer && $env.isWeb;
}
