import { ConnectionManager } from '@oasis/fluid-interop';
import {
  COLLABORATION_EXTENSION_NAME,
  CollaborationExtensionType,
} from '@oasis/fluid-interop/concurrentCollaborationExtension/concurrentCollaboration.lmv.extension';
import { onCameraChanged, updateFollow } from '@oasis/fluid-interop/concurrentCollaborationExtension/main';
import { Oasis } from '@oasis/sdk';
import throttle from 'lodash/throttle';
import React, { useEffect, useRef } from 'react';
import { WorkshopXRViewerState } from '~/features/files/components/large-model-viewer/useWorkshopXRViewer';
import { generateAnnouncePersonPayload, getConcatenatedUserId, getName } from './helpers/mqttAvatarsSyncHelper';
import { useFeatureFlags } from './use-feature-flags';
import { useWorkshopState } from './use-workshop-state';

type signalInput = {
  content: {
    playerPosition: {
      X: number;
      Y: number;
      Z: number;
    };
    playerForwardVector: {
      X: number;
      Y: number;
      Z: number;
    };
    playerUpVector: {
      X: number;
      Y: number;
      Z: number;
    };
  };
};

const getCameraInfoForUnreal = (viewer: Autodesk.Viewing.GuiViewer3D) => {
  if (!viewer || !viewer.impl || !viewer.getCamera()) {
    Oasis.Logger.warn('getCameraInfoForUnreal: viewer or camera is not available, returning');
    return;
  }

  const camera = viewer.getCamera();
  if (!camera) return;

  const rotation = camera.getWorldDirection();
  const pos = camera.getWorldPosition();

  return {
    pos,
    rot: {
      x: rotation.x,
      y: rotation.y,
      z: rotation.z,
      w: 0,
    },
  };
};

const getCameraInfo = ({
  position,
  forward,
  up = { X: 0, Y: 0, Z: 1 },
}: {
  position: { X: number; Y: number; Z: number };
  forward: { X: number; Y: number; Z: number };
  up?: { X: number; Y: number; Z: number };
}) => {
  return {
    position: [position.X, position.Y, position.Z],
    target: [position.X + forward.X, position.Y + forward.Y, position.Z + forward.Z],
    up: [up.X, up.Y, up.Z],
  };
};

const cache = {
  destroyerCallbacks: [] as Array<() => void>,
  globalDestroyer: () => {
    cache.destroyerCallbacks.forEach(cb => cb());
  },
};

const hardcodedValues = {
  HD: {
    pos: { x: 0.0, y: 0.0, z: 0.0 },
    rot: { w: 1, x: 0, y: 0, z: 0 },
    web: 1,
    tid: 2,
  },
  LD: {
    pos: { x: 40.0, y: -15.0, z: 110.0 },
    rot: { w: 1.0, x: 0.0, y: 0.0, z: 0.0 },
    web: 1,
    tid: 2,
  },
  RD: {
    pos: { x: 40.0, y: 15.0, z: 110.0 },
    rot: { w: 1.0, x: 0.0, y: 0.0, z: 0.0 },
    web: 1,
    tid: 2,
  },
  TF: {
    parent: 2,
    scale: 1.0,
    web: 1,
    tid: 1,
  },
};

export const useUnrealLmvCamerasSync = ({
  fluidConnection,
  isCollaborativeWebViewer = false,
}: {
  fluidConnection: ConnectionManager | undefined;
  isCollaborativeWebViewer?: boolean;
}) => {
  const [isCameraSync] = useFeatureFlags(['241015-7221-unreal-lmv-cameras-sync']);
  const $debug = Oasis.Debug.useStore();
  const lastIsCameraSyncMode = useRef(isCameraSync);
  const { activeWorkshopId } = useWorkshopState();
  const $session = Oasis.Session.useStore();
  const [isAnnounced, setAnnounced] = React.useState(false);
  const cacheRef = useRef(cache);
  const $viewerState = WorkshopXRViewerState.useStore();
  const viewer = $viewerState.getViewerInstance();
  const extension = viewer?.getExtension(COLLABORATION_EXTENSION_NAME) as CollaborationExtensionType;

  useEffect(() => {
    if (isCameraSync !== lastIsCameraSyncMode.current) {
      // reload
      window.location.reload();
    }

    lastIsCameraSyncMode.current = isCameraSync;
  }, [isCameraSync]);

  useEffect(() => {
    const myself = fluidConnection?.getMyself();
    if (
      (!isCameraSync && !isCollaborativeWebViewer) ||
      fluidConnection === undefined ||
      !$session.user ||
      myself === undefined
    ) {
      return;
    }
    const combinedId = getConcatenatedUserId($session.user.id, myself.deviceId);
    const { userName, userImage, userLastName } = myself.additionalDetails;
    const updatePersonTopic = `wsq/v1/ws0/${activeWorkshopId}/mupdbj`;
    const addPersonTopic = `wsq/v1/ws1/${activeWorkshopId}/addpbj`;
    const announcePersonTopic = `wsq/v1/ws1/${activeWorkshopId}/announcepbj/${combinedId}`;
    const announceYouTopic = `wsq/v1/ws1/${activeWorkshopId}/announceyou/${combinedId}`;
    const removePersonTopic = `wsq/v1/ws1/${activeWorkshopId}/remp/${combinedId}`;
    cache.destroyerCallbacks.push(() => {
      Oasis.MessagesProvider.publish({
        topic: removePersonTopic,
        payload: {},
      });
    });

    let volumeIndicator = 0;

    const getAvatarApperance = () => {
      return {
        color: '#FFFFFF',
        state: 16,
        pose: [],
        height: 0,
        tid: 3,
        type: 2,
        uid: '',
        volume: Oasis.Voice.store.localTrack.volumeIndicator / 100.0, // VR client get data from 0 to 1
      };
    };

    if (viewer && activeWorkshopId) {
      const topic = `camera/ue`;

      const cameraChangeListener = async () => {
        if (!viewer || !viewer.impl) return;

        const unrealCameraInfo = getCameraInfoForUnreal(viewer);

        if (!unrealCameraInfo) return;

        const payload: Record<string, unknown> = {
          ...hardcodedValues,
          TF: {
            ...hardcodedValues.TF,
            pos: {
              x: unrealCameraInfo?.pos.x,
              y: unrealCameraInfo?.pos.y,
              z: unrealCameraInfo?.pos.z,
            },
            rot: {
              w: 0,
              x: unrealCameraInfo?.rot.x,
              y: unrealCameraInfo?.rot.y,
              z: unrealCameraInfo?.rot.z,
            },
          },
          device_id: combinedId,
        };

        // if user is speaking or has stopped speaking
        if (volumeIndicator !== Oasis.Voice.store.localTrack.volumeIndicator) {
          volumeIndicator = Oasis.Voice.store.localTrack.volumeIndicator;

          if (volumeIndicator == 0 || volumeIndicator >= 15) {
            // Between 0 and 15 mic will pickup background noise
            payload['AV'] = getAvatarApperance();
          }
        }

        Oasis.MessagesProvider.publish({
          topic: updatePersonTopic,
          payload: payload,
        });
      };

      if (isCollaborativeWebViewer) {
        const announcePerson = async (topic: string) => {
          const newHardcodedValues = generateAnnouncePersonPayload();

          Oasis.MessagesProvider.publish({
            topic: topic,
            payload: {
              ...newHardcodedValues,
              // @TODO: Send these when enabling autonomous mode
              // HD: hardcodedValues.HD,
              // LD: hardcodedValues.LD,
              // RD: hardcodedValues.RD,
              UD: {
                // @TODO should we add new kind of device for web?
                device: 'Windows',
                webv: 1, // User is web viewer
                follow: 1, /// User is following host
                image: userImage?.replace('x120', 'x360'),
                name: getName(userName, userLastName || ''),
                tid: 4,
                version: 'WebViewer',
              },
              AV: getAvatarApperance(),
              device_id: combinedId,
            },
          });
        };

        if (!isAnnounced) {
          announcePerson(addPersonTopic);
          setAnnounced(true);
        }

        Oasis.Mqtt.subscribe(announceYouTopic, async () => {
          announcePerson(announcePersonTopic);
        })
          .then(sub => {
            if (!sub.ok) {
              console.error(`Announce subscribe error ${sub.error}`);
              return;
            }

            console.log(`Announce subscribe ok ${sub.ok}`);
            cache.destroyerCallbacks.push(() => {
              sub.value.removeHandler();
            });
          })
          .catch(err => {
            console.error(`Announce subscribe error ${err.name}: ${err.message}`);
          });
      }

      const interval = setInterval(() => {
        cameraChangeListener();
      }, 250);

      const listenerDestroyer = () => {
        clearInterval(interval);
      };

      const onCameraSignalsFollow = throttle((clientId: string, _local: boolean, payload: signalInput) => {
        if (!payload.content || !$debug.lmvCameraSync) {
          return;
        }
        const { content: cameraInfo } = payload;
        const { playerPosition, playerForwardVector, playerUpVector } = cameraInfo;

        if (!playerPosition || !playerForwardVector || !playerUpVector) {
          return;
        }

        if (!viewer.navigation.getCamera().isPerspective) {
          viewer.navigation.getCamera().toPerspective();
        }

        updateFollow({
          cameraInfo: getCameraInfo({
            position: playerPosition,
            forward: playerForwardVector,
            up: playerUpVector,
          }),
          clientId,
          extension,
          connectionManager: fluidConnection,
          viewer,
        });
      }, 60);

      const onCameraSignalUpdateUser = throttle((clientId, local, payload) => {
        if (!payload.content || !extension || !isCollaborativeWebViewer) {
          return;
        }
        const { content: cameraInfo, user } = payload;
        const { playerPosition, playerForwardVector, playerUpVector } = cameraInfo;

        if (!playerPosition || !playerUpVector || !playerForwardVector) {
          return;
        }

        if (!viewer.navigation.getCamera().isPerspective) {
          viewer.navigation.getCamera().toPerspective();
        }

        const { X, Y, Z } = playerPosition;
        const { X: FX, Y: FY, Z: FZ } = playerForwardVector;
        const { X: UX, Y: UY, Z: UZ } = playerUpVector;

        //
        updateFollow({
          cameraInfo: getCameraInfo({
            position: playerPosition,
            forward: playerForwardVector,
            up: playerUpVector,
          }),
          connectionManager: fluidConnection,
          clientId,
          extension,
          viewer,
        });

        // Update the users cursor
        onCameraChanged({
          clientId,
          extension,
          connectionManager: fluidConnection,
          cursorProps: {
            userImage: '',
            color: 0xffff00,
            position: { x: X, y: Y, z: Z },
            forward: { x: FX, y: FY, z: FZ },
          },

          message: {
            cameraInfo: {
              position: [X, Y, Z],
              direction: [FX, FY, FZ],
              rotation: [0, 0, 0],
              forward: [FX, FY, FZ],
              up: [UX, UY, UZ],
              target: [],
            },
            user: {
              ...user,
            },
            sheetId: '',
          },
        });
      }, 60);

      const listener = isCollaborativeWebViewer ? onCameraSignalUpdateUser : onCameraSignalsFollow;

      fluidConnection.onSignal(topic, listener);
      return () => {
        fluidConnection.offSignal(topic, listener);
        listenerDestroyer();
      };
    }
  }, [
    viewer,
    fluidConnection,
    isCameraSync,
    $debug.lmvCameraSync,
    activeWorkshopId,
    isCollaborativeWebViewer,
    extension,
    $session.user,
    isAnnounced,
  ]);

  return cacheRef.current;
};
