import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { useEffect, useRef, useState, type ComponentProps, type ReactNode } from 'react';

interface Props extends ComponentProps<'img'> {
  urn: string;
  fallback?: ReactNode;
  lazyload?: boolean;
  onOssError?: () => void;
}

const urlCache = new Map<string, string>();

export function OssThumbnail({ urn, fallback, lazyload, onOssError, ...props }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [src, setSrc] = useState(urlCache.get(urn) ?? '');
  const [forceFallback, setForceFallback] = useState(false);

  useEffect(() => {
    let controller: AbortController | undefined;

    if (ref.current) {
      async function fetchThumbnail() {
        controller = new AbortController();

        const res = await Oasis.Files.getSignedUrl(urn, { signal: controller.signal });

        if (!res.ok) {
          onOssError?.();
          return;
        }

        setSrc(res.value.url);
        urlCache.set(urn, res.value.url);
      }

      if (!lazyload) {
        fetchThumbnail();
        return () => controller?.abort('<OssThumbnail /> unmounted');
      }

      function callback(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            fetchThumbnail();
            observer.disconnect();
          }
        }
      }

      const observer = new IntersectionObserver(callback);
      observer.observe(ref.current);

      return () => {
        controller?.abort();
        observer.disconnect();
      };
    }
  }, [urn, lazyload, onOssError]);

  return (
    <div ref={ref} className="flex items-center justify-center">
      {src && !forceFallback ? (
        <img
          {...props}
          src={src}
          className={clsx(src && 'bg-white', props.className)}
          onError={e => {
            setForceFallback(true);
            props.onError?.(e);
          }}
        />
      ) : (
        fallback
      )}
    </div>
  );
}
