import { Oasis } from '@oasis/sdk';
import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { Queries } from '~/shared/hooks/queries';
import { Subscriptions } from '~/shared/hooks/subscriptions';
import { useWorkshopState } from '~/shared/hooks/use-workshop-state';

interface Props {
  children?: ReactNode;
}

export default function ListenerBindingLayout({ children }: Props) {
  const $env = Oasis.Env.useStore();

  return (
    <>
      <_Listeners />
      {$env.isVr && <_VrOnlyListeners />}
      {children}
      <Outlet />
    </>
  );
}

function _VrOnlyListeners() {
  // We connect always to the workshop state in VR mode
  useWorkshopState({ connectFluidBasedOnContext: true });
  return null;
}

function _Listeners() {
  Queries.Users.useListUsersDevices(); // preload user devices for pairing modal
  Subscriptions.useCommands(); // listen for notifications
  return null;
}
