import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { useWorkshopAudience } from '~/shared/hooks/use-workshop-audience';

interface Props {
  workshopId: string;
}

export function SpeakerIndicator({ workshopId }: Props) {
  const $voice = Oasis.Voice.useStore(workshopId);
  const $audience = useWorkshopAudience();

  if (!$voice.activeSpeaker) {
    return null;
  }

  const speaker = $audience.allUsers.find(user => {
    return user.userId === $voice.activeSpeaker?.userId;
  });

  const speakerName = speaker?.additionalDetails.userName || 'Someone';

  return (
    <p
      className={clsx(
        'absolute left-1/2 -translate-x-1/2 bottom-20 rounded-full',
        'text-body-md text-white bg-charcoal-900 px-4 py-[6px] items-center'
      )}
    >
      <span className="font-bold">{speakerName}</span> is speaking...
    </p>
  );
}
