import Avatar from '@adsk/alloy-react-avatar';
import Button from '@adsk/alloy-react-button';
import { MoreVerticalIcon } from '@adsk/alloy-react-icon';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { Popover } from '~/shared/components/base/popover';
import { MicIcon } from '~/shared/components/icons/mic';
import { MicOffIcon } from '~/shared/components/icons/mic-off';
import type { WorkshopPerson } from '../hooks/use-workshop-people';

interface Props {
  user: WorkshopPerson;
  currentUser?: WorkshopPerson;
  displayMode: 'viewer-panel' | 'vr-people-menu';
  isCurrentUsersListItem?: boolean;
  isHostsListItem?: boolean;
  showShadow?: boolean;
}

export function WorkshopPeopleListItem({
  user,
  currentUser,
  displayMode,
  isCurrentUsersListItem,
  isHostsListItem,
  showShadow,
}: Props) {
  const $env = Oasis.Env.useStore();
  const isCompact = displayMode === 'viewer-panel';

  return (
    <li
      className={clsx(
        'flex items-center border-b border-charcoal-200 px-4 hover:bg-charcoal-50',
        isCompact ? 'py-3' : 'py-4',
        isCurrentUsersListItem && 'transition-shadow sticky top-0 bg-white z-10',
        isCurrentUsersListItem && showShadow && 'shadow-low'
      )}
    >
      <div className="flex items-center">
        <div
          className={clsx('rounded-full border-4', isHostsListItem ? 'border-blue-300' : 'border-transparent')}
        >
          <Avatar name={user.name} id={user.userId} imageUrl={user.avatarUrl} size="medium" />
        </div>
        <div className="ml-2">
          <p className="font-bold">{isCurrentUsersListItem ? `${user.name} (Me)` : user.name}</p>
          <p className="text-body-sm text-charcoal-500">
            {isHostsListItem ? (
              'VR host (following)'
            ) : (
              <>
                {user.device === 'VR' && 'VR collaborator'}
                {user.device === 'DESKTOP' && 'Desktop viewer'}
                {user.device === 'WEB' && 'Web viewer'}
                {user.device === 'WEB-VR' && 'Console'}
              </>
            )}
          </p>
        </div>
      </div>

      <div className={clsx('ml-auto flex items-center', isCompact ? 'gap-2' : 'gap-4')}>
        {$env.isDevMode && (isCurrentUsersListItem || (user.device !== 'WEB' && !isCompact)) && (
          <>
            {isCompact ? (
              <Popover>
                <Popover.Trigger className="data-state-open:text-blue-500">
                  <div className="flex items-center justify-center w-6 h-6">
                    <MoreVerticalIcon />
                  </div>
                </Popover.Trigger>

                <Popover.Content align="end" hideArrow className="!min-w-36 mt-2">
                  <_Actions
                    user={user}
                    currentUser={currentUser}
                    isCurrentUsersListItem={isCurrentUsersListItem}
                    inPopover
                  />
                </Popover.Content>
              </Popover>
            ) : (
              <_Actions user={user} currentUser={currentUser} isCurrentUsersListItem={isCurrentUsersListItem} />
            )}
          </>
        )}

        <_MuteButton user={user} />
      </div>
    </li>
  );
}

interface MuteButtonProps {
  user: WorkshopPerson;
}

function _MuteButton({ user }: MuteButtonProps) {
  const $voice = Oasis.Voice.useStore();

  if (!user.isCurrentUser) {
    return (
      <button
        className={clsx(
          'flex items-center justify-center w-6 h-6 text-charcoal-700',
          user.isMuted ? 'text-red-500 hover:text-red-700' : 'hover:text-blue-500'
        )}
        onClick={() => {
          if (user.isMuted) {
            Oasis.WorkshopPeopleLpc.unmute(user.deviceId);
          } else {
            Oasis.WorkshopPeopleLpc.mute(user.deviceId);
          }
        }}
      >
        {user.isMuted ? <MicOffIcon className="w-6 h-6" /> : <MicIcon className="w-6 h-6" />}
      </button>
    );
  }

  return (
    <button
      className={clsx(
        'flex items-center justify-center w-6 h-6 text-charcoal-700',
        $voice.localTrack.muted ? 'text-red-500 hover:text-red-700' : 'hover:text-blue-500'
      )}
      onClick={() => {
        Oasis.Voice.toggleLocalTrackMuted();

        if ($voice.localTrack.muted) {
          Oasis.WorkshopPeopleLpc.unmute(user.deviceId);
        } else {
          Oasis.WorkshopPeopleLpc.mute(user.deviceId);
        }
      }}
    >
      {$voice.localTrack.muted ? <MicOffIcon className="w-6 h-6" /> : <MicIcon className="w-6 h-6" />}
    </button>
  );
}

interface ActionsProps {
  user: WorkshopPerson;
  currentUser?: WorkshopPerson;
  isCurrentUsersListItem?: boolean;
  inPopover?: boolean;
}

function _Actions({ user, currentUser, isCurrentUsersListItem, inPopover }: ActionsProps) {
  if (isCurrentUsersListItem) {
    if (inPopover) {
      return (
        <div>
          {currentUser?.canGatherOthers && (
            <Popover.Button hideIcon onClick={() => Oasis.WorkshopPeopleLpc.gatherAll()}>
              Gather all
            </Popover.Button>
          )}
          {currentUser?.canMuteOthers && (
            <Popover.Button hideIcon onClick={() => Oasis.WorkshopPeopleLpc.muteAll()} className="rounded">
              Mute all
            </Popover.Button>
          )}
        </div>
      );
    }

    return (
      <div className="flex gap-4">
        {currentUser?.canGatherOthers && (
          <Button variant="secondary" onClick={() => Oasis.WorkshopPeopleLpc.gatherAll()}>
            Gather all
          </Button>
        )}
        {currentUser?.canMuteOthers && (
          <Button variant="primary" onClick={() => Oasis.WorkshopPeopleLpc.muteAll()}>
            Mute all
          </Button>
        )}
      </div>
    );
  }

  if (inPopover) {
    return (
      <div className="py-2">
        {currentUser?.canGatherOthers && (
          <Popover.Button hideIcon onClick={() => Oasis.WorkshopPeopleLpc.gather(user.deviceId)}>
            Gather
          </Popover.Button>
        )}
        <Popover.Button
          hideIcon
          onClick={() => Oasis.WorkshopPeopleLpc.goToUser(user.deviceId)}
          disabled={!user.canBeGotoed}
        >
          Go-to
        </Popover.Button>
      </div>
    );
  }

  return (
    <div className="flex gap-4">
      {currentUser?.canGatherOthers && (
        <Button
          variant="secondary"
          onClick={() => Oasis.WorkshopPeopleLpc.gather(user.deviceId)}
          disabled={!user.canBeGathered}
        >
          Gather
        </Button>
      )}
      <Button
        variant="primary"
        onClick={() => Oasis.WorkshopPeopleLpc.goToUser(user.deviceId)}
        disabled={!user.canBeGotoed}
      >
        Go-to
      </Button>
    </div>
  );
}
