import { Oasis } from '@oasis/sdk';
import { useEffect, useMemo } from 'react';
import { useWorkshopAudience } from '~/shared/hooks/use-workshop-audience';

export interface WorkshopPerson {
  userId: string;
  deviceId: string;
  device: 'VR' | 'WEB' | 'WEB-VR' | 'DESKTOP' | 'UNKNOWN';
  avatarUrl?: string;
  name: string;
  isCurrentUser: boolean;
  isHost: boolean;
  isMuted: boolean;
  canMuteOthers: boolean;
  canGatherOthers: boolean;
  canBeGathered: boolean;
  canBeGotoed: boolean;
}

export function useWorkshopPeople(workshopId: string) {
  const $audience = useWorkshopAudience(workshopId);
  const $workshopLpc = Oasis.WorkshopPeopleLpc.useStore();

  useEffect(() => {
    Oasis.WorkshopPeopleLpc.listen();

    return () => {
      Oasis.WorkshopPeopleLpc.unlisten();
      Oasis.WorkshopPeopleLpc.store.users.clear();
    };
  }, []);

  const users = useMemo(() => {
    let currentUser: WorkshopPerson | undefined;
    let host: WorkshopPerson | undefined;
    const viewers: WorkshopPerson[] = new Array<WorkshopPerson>();

    for (let index = 0; index < $audience.allUsers.length; index++) {
      const audienceUser = $audience.allUsers[index];

      if (audienceUser) {
        const lpcUser = $workshopLpc.users.get(audienceUser.deviceId);
        const { userName, userLastName, device } = audienceUser.additionalDetails;

        const person: WorkshopPerson = {
          userId: audienceUser.userId,
          deviceId: audienceUser.deviceId,
          device: device ?? 'UNKNOWN',
          name: `${userName} ${userLastName}`,
          isCurrentUser: index === 0,
          isHost: index === 1,
          isMuted: lpcUser?.isMuted ?? false,
          canBeGathered: lpcUser?.canBeGathered ?? false,
          canBeGotoed: lpcUser?.canBeGotoed ?? false,
          canGatherOthers: lpcUser?.canGatherOthers ?? false,
          canMuteOthers: lpcUser?.canMuteOthers ?? false,
        };

        if (person.isCurrentUser) {
          currentUser = person;
        } else if (person.isHost) {
          host = person;
        } else {
          viewers.push(person);
        }
      }
    }

    return {
      currentUser,
      host,
      viewers,
    };
  }, [$audience.allUsers, $workshopLpc.users]);

  return users;
}
