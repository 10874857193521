import AgoraRTC, {
  type ConnectionState,
  type IAgoraRTCRemoteUser,
  type IMicrophoneAudioTrack,
  type UID,
} from 'agora-rtc-sdk-ng';
import { useEffect, useRef } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { proxyMap } from 'valtio/utils';
import { Err, Ok } from '../../lib/result';
import { Oasis } from '../../oasis';
import { Agora } from '../../providers/agora/agora.provider';

type Speaker = { userId: string; deviceId: string; volumeLevel: number };
type VoiceUser = { remoteId: string; userId: string; deviceId: string; volumeLevel: number; isMuted: boolean };

interface Store {
  active: boolean;
  status: ConnectionState;
  localTrack: {
    volume: number; // What the user has set their playback volume to
    volumeIndicator: number; // What the user's current input levels are at
    muted: boolean;
    activeInputId: string;
    activeOutputId: string;
    userId: string;
    deviceId: string;
  };
  workshop: { id: string; name: string } | undefined;
  users: Map<string, VoiceUser>;
  activeSpeaker: Speaker | undefined;
}

function _getDefaultState(): Store {
  return {
    active: false,
    status: 'DISCONNECTED',
    localTrack: proxy({
      volume: 100,
      volumeIndicator: 0,
      muted: true,
      activeInputId: 'default',
      activeOutputId: 'default',
      userId: '',
      deviceId: '',
    }),
    workshop: undefined,
    users: proxyMap(),
    activeSpeaker: undefined,
  };
}

export const Voice = {
  mic: undefined as IMicrophoneAudioTrack | undefined,
  connections: new Map<string, IAgoraRTCRemoteUser>(),
  store: proxy<Store>(_getDefaultState()),

  useStore: (workshopId?: string) => {
    const prevWorkshopId = useRef(workshopId);

    useEffect(() => {
      if (prevWorkshopId.current !== workshopId) {
        prevWorkshopId.current = workshopId;
        Voice.resetStore();
      }
    }, [workshopId, prevWorkshopId]);

    return useSnapshot(Voice.store);
  },

  init() {
    Agora.client.on('exception', event => {
      Oasis.Logger.error({ msg: '[Voice] Exception', event });
    });

    Agora.client.on('crypt-error', () => {
      Oasis.Logger.error({ msg: '[Voice] Crypt Error' });
    });

    Agora.client.on('connection-state-change', status => {
      Voice.store.status = status;
    });

    Agora.client.on('user-published', async (user, mediaType) => {
      console.log('user-published', user, mediaType);
      if (mediaType === 'audio') {
        const track = await Agora.client.subscribe(user, 'audio');
        track.setVolume(Voice.store.localTrack.volume);
        track.play();

        const { deviceId } = Voice.splitUid(user.uid);
        Voice.connections.set(deviceId, user);
      }
    });

    Agora.client.on('user-unpublished', user => {
      user.audioTrack?.stop();
      Agora.client.unsubscribe(user);
    });

    Agora.client.on('user-info-updated', (uid, type) => {
      const { deviceId } = Voice.splitUid(uid);

      if (type === 'mute-audio') {
        Voice.muteDevice(deviceId);
      }

      if (type === 'unmute-audio') {
        Voice.unmuteDevice(deviceId);
      }
    });

    Agora.client.on('volume-indicator', userVolumes => {
      console.log(userVolumes);
      for (const userVolume of userVolumes) {
        const { userId, deviceId } = Voice.splitUid(userVolume.uid);
        Voice.updateVoiceUser(deviceId, { volumeLevel: userVolume.level });
        Voice.determineIfActiveSpeaker({ userId, deviceId, volumeLevel: userVolume.level });
      }
    });
  },

  splitUid(uid: UID) {
    const [userId, deviceId] = uid.toString().split('_');

    if (!userId || !deviceId) {
      Oasis.Logger.error('[Voice] Invalid userId or deviceId');
      throw new Error('Invalid voice uid');
    }

    return { userId, deviceId };
  },

  muteDevice(deviceId: string) {
    const connection = Voice.connections.get(deviceId);

    if (connection) {
      connection.audioTrack?.setVolume(0);
      Voice.updateVoiceUser(deviceId, { isMuted: true });
    }
  },

  unmuteDevice(deviceId: string) {
    const connection = Voice.connections.get(deviceId);

    if (connection) {
      connection.audioTrack?.setVolume(Voice.store.localTrack.volume);
      Voice.updateVoiceUser(deviceId, { isMuted: false });
    }
  },

  updateVoiceUser(deviceId: string, data: Partial<VoiceUser>) {
    const user = Voice.connections.get(deviceId);

    if (user) {
      Voice.connections.set(deviceId, { ...user, ...data });
    }
  },

  async requestMicTrack() {
    Voice.mic = await Agora.createMicrophoneAudioTrack();
    Voice.mic.setMuted(true);
    return Voice.mic;
  },

  async connectToWorkshop(params: { workshopId: string }) {
    const workshop = await Oasis.Workshops.findWorkshopById(params.workshopId);

    if (!workshop.ok) {
      Voice.store.active = false;
      return workshop;
    }

    if (!workshop.value.settings?.voice?.channelToken || !workshop.value.settings?.voice?.userChannelId) {
      Voice.store.active = false;
      Oasis.Logger.error('[Voice.connect] Voice `channelToken` or `userChannelId` is missing');
      return Err({ code: 'INVALID_CHANNEL_TOKEN' });
    }

    if (!Oasis.Session.store.user) {
      return Err({ code: 'UNAUTHORIZED' });
    }

    const { userId, deviceId } = Voice.splitUid(workshop.value.settings.voice.userChannelId);

    if (!userId || !deviceId) {
      Voice.store.active = false;
      Oasis.Logger.error('[Voice.connect] Invalid userId or deviceId');
      return Err({ code: 'INVALID_CHANNEL_TOKEN' });
    }

    const connectResult = await Agora.connect({
      channel: workshop.value.id,
      channelToken: workshop.value.settings.voice.channelToken,
      uid: workshop.value.settings.voice.userChannelId,
      encryptionMode: workshop.value.settings.voice.encryptionMode,
      encryptionKey: workshop.value.settings.voice.encryptionKey,
      encryptionSalt64BitEncoded: workshop.value.settings.voice.encryptionSalt64BitEncoded,
    });

    if (!connectResult.ok) {
      Voice.store.status = 'DISCONNECTED';
      return connectResult;
    }

    if (!Voice.mic) {
      await Voice.requestMicTrack();
    }

    if (Voice.mic) {
      Agora.client.enableAudioVolumeIndicator();
      await Agora.client.publish(Voice.mic);
    }

    Voice.store.active = true;
    Voice.store.localTrack.userId = userId;
    Voice.store.localTrack.deviceId = deviceId;
    Voice.store.workshop = {
      id: workshop.value.id,
      name: workshop.value.name,
    };

    const refreshToken = async () => {
      const workshop = await Oasis.Workshops.findWorkshopById(params.workshopId);

      if (workshop.ok && workshop.value.settings?.voice?.channelToken) {
        await Agora.client.renewToken(workshop.value.settings.voice.channelToken);
      }
    };

    Agora.client.on('token-privilege-will-expire', refreshToken);

    return Ok({
      micTrack: Voice.mic,
      disconnectFromWorkshop: () => {
        Agora.client.off('token-privilege-will-expire', refreshToken);
        Voice.disconnect();
      },
    });
  },

  async getDevices() {
    return {
      inputs: (await AgoraRTC.getMicrophones()).sort(device => (device.deviceId === 'default' ? -1 : 0)),
      outputs: (await AgoraRTC.getPlaybackDevices()).sort(device => (device.deviceId === 'default' ? -1 : 0)),
    };
  },

  setLocalTrackVolume(volume: number) {
    Voice.store.localTrack.volume = volume;
    for (const user of Voice.connections.values()) {
      user.audioTrack?.setVolume(volume);
    }
  },

  setLocalTrackMute(muted: boolean) {
    Voice.store.localTrack.muted = muted;
    Voice.mic?.setMuted(muted);
  },

  toggleLocalTrackMuted() {
    const muted = !Voice.store.localTrack.muted;
    Voice.store.localTrack.muted = muted;
    Voice.mic?.setMuted(muted);
  },

  setLocalTrackVolumeIndicator(level: number) {
    Voice.store.localTrack.volumeIndicator = level;
  },

  determineIfActiveSpeaker(speaker: Speaker) {
    const activeSpeakerLevel = Voice.store.activeSpeaker?.volumeLevel || 0;

    if (speaker.volumeLevel > activeSpeakerLevel) {
      Voice.store.activeSpeaker = speaker.volumeLevel > 50 ? speaker : undefined;
    }

    if (speaker.deviceId === Voice.store.localTrack.deviceId && speaker.volumeLevel < 50) {
      Voice.store.activeSpeaker = undefined;
    }
  },

  resetStore() {
    const state = _getDefaultState();

    Voice.store.active = state.active;
    Voice.store.status = state.status;
    Voice.store.localTrack.volume = state.localTrack.volume;
    Voice.store.localTrack.muted = state.localTrack.muted;
    Voice.store.localTrack.activeInputId = state.localTrack.activeInputId;
    Voice.store.localTrack.activeOutputId = state.localTrack.activeOutputId;
    Voice.store.workshop = state.workshop;
    Voice.store.users.clear();
  },

  async disconnect() {
    await Agora.disconnect();
    Voice.mic?.close();
    Voice.mic = undefined;
    Voice.connections.clear();
    Voice.resetStore();
  },
};
