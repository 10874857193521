import { CollaborationExtension, ConnectionManager, WorkshopXRUser } from '../main';
import { getFollowUserTool } from './followUserTool';
import { CursorsManager } from './rendering/cursorManager';
const EXTENSION_NAME = 'Autodesk.ConcurrentCollaboration';

export const COLLABORATION_EXTENSION_NAME = EXTENSION_NAME;

export default function <T extends typeof Autodesk.Viewing>(av: T) {
  /**
   * Extension description
   *
   * The extension id is: 'Autodesk.ConcurrentCollaboration'
   *
   * @example
   *   viewer.loadExtension('Autodesk.ConcurrentCollaboration', options)
   *
   * @memberof Autodesk.Viewing.Extensions
   * @alias Autodesk.Viewing.Extensions.ConcurrentCollaboration
   * @see {@link Autodesk.Viewing.Extension} for common inherited methods.
   * @class
   */
  class ConcurrentCollaborationExtension extends av.Extension {
    name: string;
    private _sessionID: string | undefined;
    private _showLaserPointers: boolean;
    private _users: Map<string, WorkshopXRUser>;
    private _followedId: string | undefined;
    connectionManager: ConnectionManager | undefined;
    cursorsManager: CursorsManager;
    followUserTool: any;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
      super(viewer, options);
      this.name = 'ConcurrentCollaboration';

      this._sessionID = undefined;
      this._showLaserPointers = true;
      this._users = new Map();
      this._followedId = undefined;

      this.connectionManager = undefined;
      this.cursorsManager = new CursorsManager(this);
    }

    override load(): boolean {
      super.load();

      this.followUserTool = getFollowUserTool();
      return true;
    }

    override unload(): boolean {
      super.unload();
      this.cursorsManager.cursors.forEach(cursor => this.cursorsManager.remove(cursor.id));
      return true;
    }

    override deactivate(): boolean {
      super.deactivate();
      return true;
    }

    get myself(): string | undefined {
      return this.connectionManager?.getMyself()?.userId;
    }

    getSessionId(): string | undefined {
      return this._sessionID;
    }

    addUser(user?: WorkshopXRUser, update: boolean = true): void {
      const deviceId = user?.deviceId;
      if (!deviceId) {
        console.warn('deviceId not found in user', user);
        return;
      }

      if (this._users.has(deviceId)) {
        console.warn('User already exists', user);
        return;
      }

      this.cursorsManager.add(
        deviceId,
        {
          color: user.additionalDetails.color,
          userImage: user.additionalDetails.userImage!,
          firstName: user.additionalDetails.userName,
          lastName: user.additionalDetails.userName,
        },
        this.currentSheetId
      );

      if (update) {
        this._users.set(deviceId, user);
      }
    }

    removeUser(user: WorkshopXRUser): void {
      const deviceId = user?.deviceId;
      if (!deviceId) {
        console.warn('deviceId not found in user', user);
        return;
      }

      this.cursorsManager.remove(deviceId);
      this.viewer.impl.invalidate(/* needsClear */ true, /* needsRender */ false, /* overlayDirty */ true);
    }

    get users(): WorkshopXRUser[] {
      return Array.from(this._users.values());
    }

    set showLaserPointers(show: boolean) {
      this._showLaserPointers = show;
      if (this.cursorsManager) {
        this.cursorsManager.showLaserPointers(show);
      }
    }

    get showLaserPointers(): boolean {
      return this._showLaserPointers;
    }

    get followedId(): string | undefined {
      return this._followedId;
    }

    async startFollow(id: string): Promise<void> {
      if (id && this._followedId !== id) {
        this._followedId = id;
      } else {
        this._followedId = undefined;
      }
    }

    isFollowing(): boolean {
      return !!this._followedId;
    }

    get currentSheetId(): string {
      /**
       * @TODO the extension should be sheetId aware.
       */
      return 'default';
    }
  }

  av.theExtensionManager.registerExtension(EXTENSION_NAME, ConcurrentCollaborationExtension);

  return ConcurrentCollaborationExtension;
}

export type CollaborationExtensionType = InstanceType<ReturnType<typeof CollaborationExtension>> &
  Autodesk.Viewing.Extension;
