import { useMemo } from 'react';
import { Tooltip } from '~/shared/components/base/tooltip';
import { useWorkshopAudience } from '~/shared/hooks/use-workshop-audience';

const STACKED_USERS: Array<{ name: string; device: string }> = [
  // { name: 'Anne Harris', device: 'Web' },
  // { name: 'Bill Smith', device: 'Web' },
  // { name: 'Carolyn Smith', device: 'VR' },
  // { name: 'Daniel Johnson', device: 'VR' },
  // { name: 'Evelyn Johnson', device: 'VR' },
  // // { name: 'Franklin "Scooter" McHasasuperlongname', device: 'VR' },
  // { name: 'George Johnson', device: 'VR' },
  // { name: 'Hannah Johnson', device: 'VR' },
];

export function FollowingBorderStack() {
  const $workshopAudience = useWorkshopAudience();

  const host = useMemo(() => {
    const host = $workshopAudience.hostUser;

    if (host) {
      return `${host.additionalDetails.userName} ${host.additionalDetails.userLastName}`;
    }
  }, [$workshopAudience.hostUser]);

  if (!host) {
    return null;
  }

  return (
    <>
      <div className="absolute inset-0 flex-1 border-[3px] border-blue-300 flex items-start justify-center pointer-events-none" />

      <div className="absolute left-1/2 top-0 -translate-x-1/2 -ml-6">
        <Tooltip
          placement="bottom"
          className="mt-2"
          content={
            STACKED_USERS.length > 0 && (
              <ul className="min-w-[216px]">
                {STACKED_USERS.map(user => (
                  <li key={user.name} className="text-label-sm text-white">
                    {user.name} ({user.device})
                  </li>
                ))}
              </ul>
            )
          }
        >
          {/* The `-ml-6` bumps it over to account for the page's navigation */}
          <p className="px-2 py-1 rounded-b bg-blue-300 text-label-sm">
            Following <span className="font-bold">{host}</span>{' '}
            {STACKED_USERS.length > 0 && (
              <>
                with {STACKED_USERS.length} other{STACKED_USERS.length > 1 && 's'}
              </>
            )}
          </p>
        </Tooltip>
      </div>
    </>
  );
}
