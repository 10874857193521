import { useEffect, useRef, useState, type ComponentProps } from 'react';
import { useWorkshopPeople } from '../hooks/use-workshop-people';
import { WorkshopPeopleListItem } from './workshop-people-list-item';

interface Props {
  workshopId: string;
  displayMode: ComponentProps<typeof WorkshopPeopleListItem>['displayMode'];
}

export function WorkshopPeopleList({ workshopId, displayMode }: Props) {
  const $people = useWorkshopPeople(workshopId);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const container = scrollContainer.current;

    if (!container) {
      return;
    }

    const handleScroll = () => {
      if (container.scrollTop > 12) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [scrollContainer]);

  return (
    <div ref={scrollContainer} className="flex-1 overflow-auto">
      <ul>
        {/* Current user */}
        {$people.currentUser && (
          <WorkshopPeopleListItem
            displayMode={displayMode}
            user={$people.currentUser}
            currentUser={$people.currentUser}
            isCurrentUsersListItem
            showShadow={hasScrolled}
          />
        )}

        {/* Host */}
        {$people.host && (
          <WorkshopPeopleListItem
            displayMode={displayMode}
            user={$people.host}
            currentUser={$people.currentUser}
            isHostsListItem
          />
        )}

        {/* Viewers */}
        {$people.viewers.map(user => (
          <WorkshopPeopleListItem
            displayMode={displayMode}
            key={user.deviceId}
            user={user}
            currentUser={$people.currentUser}
          />
        ))}
      </ul>
    </div>
  );
}
