import { setRegion, setEnv } from '@adsk/acs-sc-web-platform-api';
import { Token } from '@adsk/acs-sc-web-platform-api/es/token/fetchToken';

import { Oasis } from '@oasis/sdk';

// Looks like we arent the only ones that run into auth/config issues.
// https://git.autodesk.com/BIM360/bim360-navisworks-issues-ui/blob/e1ac74faf9edb2abc42e2051d085cebd56dd5537/src/helpers/configureAcc.ts
export function configureAcc() {
  setRegion(Oasis.Session.store.region); // Set region string 🧙‍♂️
  const releaseChannel = Oasis.Env.store.releaseChannel;

  setEnv('PRODUCTION');

  if (
    // @TODO This to be removed when changing alpha to point to prod
    releaseChannel === 'alpha' ||
    releaseChannel === 'devstg'
  ) {
    setEnv('STAGING');
  }

  window['__ACSGetAccessTokenVersion'] = 1;
  window['__ACSGetAccessToken'] = async (): Promise<Token> => {
    const result = await Oasis.TokenManager.getAccessToken();
    const expiration = Oasis.TokenManager.getExpiration();
    if (result.ok) {
      return {
        accessToken: result.value,
        expiresAt: expiration.toString(),
      };
    } else {
      throw new Error('Failed to verify session.');
    }
  };
}
