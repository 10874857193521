import { LocaleProvider } from '@adsk/alloy-react-locale';
import { Oasis } from '@oasis/sdk';
import { ArrayUtils } from '@oasis/utils';
import { wrapCreateBrowserRouter } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactNode, Suspense, useEffect } from 'react';
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom';
import AgreementPage from './features/auth/pages/agreement/page';
import LoginPage from './features/auth/pages/login/page';
import OauthLoginHandlerPage from './features/auth/pages/oauth-login-handler/page';
import OauthPairingHandlerPage from './features/auth/pages/oauth-pairing-handler/page';
import OauthPairingRedirectPage from './features/auth/pages/oauth-pairing-redirect/page';
import OauthRedirectPage from './features/auth/pages/oauth-redirect/page';
import OauthTrialHandlerPage from './features/auth/pages/oauth-trial-handler/page';
import InspectorPage from './features/dev-mode/pages/inspector/page';
import FilesIndexPage from './features/files/pages/index/page';
import FilesShowPage from './features/files/pages/show/page';
import IssuesIndexPage from './features/issues/pages/index/page';
import MembersPage from './features/members/pages/index/page';
import ProjectsIndexPage from './features/projects/pages/index/page';
import WorkshopsIndexPage from './features/workshops/pages/index/page';
import { WorkshopShowPage } from './features/workshops/pages/show/page';
import { WorkshopViewerPage } from './features/workshops/pages/viewer/page';
import { PeopleMenuVrPage } from './features/workshops/vr-pages/people-menu/page';
import { NotificationManager } from './shared/components/base/notification-manager';
import { OasisErrorBoundary } from './shared/components/base/oasis-error-boundary';
import { useVrInputEvents } from './shared/hooks/use-vr-input-events';
import DashboardLayout from './shared/layouts/dashboard/layout';
import ListenerBindingLayout from './shared/layouts/listener-binding/layout';
import ProjectLayout from './shared/layouts/project/layout';
import RootLayout from './shared/layouts/root';
import AboutPage from './shared/pages/about/page';
import DownloadPage from './shared/pages/download/page';
import FeedbackPage from './shared/pages/feedback/page';
import IndexPage from './shared/pages/index/page';
import InstallingPage from './shared/pages/installing/page';
import { AppHistory } from './shared/utils/app-history';
import { queryClient } from './shared/utils/query-client';

console.log(
  'REACT_APP_ACS_ENV',
  process.env.REACT_APP_ACS_ENV,
  'REACT_APP_ACS_REGION',
  process.env.REACT_APP_ACS_REGION,
  'PUBLIC_ACM_NAMESPACE',
  process.env.PUBLIC_ACM_NAMESPACE,
  'PUBLIC_FLUID_ENV_STR',
  process.env.PUBLIC_FLUID_ENV_STR
);

interface Props {
  children?: ReactNode;
}

const router = wrapCreateBrowserRouter(createBrowserRouter)(
  [
    {
      path: '/about',
      element: (
        <Route>
          <AboutPage />
        </Route>
      ),
    },
    {
      path: '/feedback',
      element: (
        <Route>
          <FeedbackPage />
        </Route>
      ),
    },
    {
      path: '/oauth',
      element: (
        <Route>
          <OauthRedirectPage />
        </Route>
      ),
    },
    {
      path: '/oauth/login',
      element: (
        <Route>
          <OauthLoginHandlerPage />
        </Route>
      ),
    },
    {
      path: '/oauth/trial',
      element: (
        <Route>
          <OauthTrialHandlerPage />
        </Route>
      ),
    },
    {
      path: '/oauth/pairing-code',
      element: (
        <Route>
          <OauthPairingHandlerPage />
        </Route>
      ),
    },
    {
      path: '/pair',
      element: (
        <Route>
          <OauthPairingRedirectPage />
        </Route>
      ),
    },
    {
      path: '/',
      element: (
        <Suspense fallback={null}>
          <RootLayout />
        </Suspense>
      ),
      children: [
        {
          path: '/',
          element: (
            <Route>
              <IndexPage />
            </Route>
          ),
        },
        {
          path: '/login',
          element: (
            <Route>
              <LoginPage />
            </Route>
          ),
        },
        {
          path: '/trial',
          element: (
            <Route>
              <LoginPage isTrial />
            </Route>
          ),
        },
        {
          path: '/trial-agreement',
          element: (
            <ProtectedRoute>
              <AgreementPage isTrial />
            </ProtectedRoute>
          ),
        },
        {
          path: '/download',
          element: (
            <ProtectedRoute>
              <DownloadPage />
            </ProtectedRoute>
          ),
        },
        {
          path: '/projects/:projectId/workshops/:workshopId/viewer',
          element: (
            <Route>
              <WorkshopViewerPage />
            </Route>
          ),
        },

        // This "route" houses VR specific UI as well as standard web routes.
        // The `ListenerBindingLayout` is used to listen to global subscriptions and events.
        {
          path: '/projects',
          element: (
            <Suspense fallback={null}>
              <ListenerBindingLayout />
            </Suspense>
          ),
          children: [
            // Standard web routes
            {
              path: '/projects/',
              element: (
                <Suspense fallback={null}>
                  <DashboardLayout />
                </Suspense>
              ),
              children: [
                {
                  path: '/projects/',
                  element: (
                    <ProtectedRoute>
                      <ProjectsIndexPage />
                    </ProtectedRoute>
                  ),
                },
                {
                  path: '/projects/:projectId',
                  element: (
                    <ProtectedRoute>
                      <ProjectLayout />
                    </ProtectedRoute>
                  ),
                  children: [
                    {
                      path: '/projects/:projectId',
                      element: (
                        <Route>
                          <WorkshopsIndexPage />
                        </Route>
                      ),
                      children: [
                        {
                          path: '/projects/:projectId/workshops/:workshopId',
                          element: (
                            <Route>
                              <WorkshopShowPage />
                            </Route>
                          ),
                        },
                      ],
                    },
                    {
                      path: '/projects/:projectId/files',
                      element: <FilesIndexPage />,
                    },
                    {
                      path: '/projects/:projectId/files/:documentId',
                      element: (
                        <ProtectedRoute>
                          <FilesShowPage />
                        </ProtectedRoute>
                      ),
                    },
                    {
                      path: '/projects/:projectId/issues',
                      element: <IssuesIndexPage />,
                    },
                    {
                      path: '/projects/:projectId/members',
                      element: <MembersPage />,
                    },
                    {
                      path: '/projects/:projectId/inspector',
                      element: (
                        <DevModeRoute>
                          <InspectorPage />
                        </DevModeRoute>
                      ),
                    },
                  ].filter(ArrayUtils.truthy),
                },
              ],
            },

            // VR UI routes
            {
              path: '/projects/:projectId/workshops/:workshopId/vr/people',
              element: (
                <Route>
                  <PeopleMenuVrPage />
                </Route>
              ),
            },
          ],
        },
      ],
    },
    {
      path: '/installing',
      element: (
        <Route>
          <InstallingPage />
        </Route>
      ),
    },
  ].filter(ArrayUtils.truthy)
);

export function App({ children }: Props) {
  useVrInputEvents();

  return (
    <OasisErrorBoundary>
      <LocaleProvider locale="en-US">
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>

        {children}

        <NotificationManager />
      </LocaleProvider>
    </OasisErrorBoundary>
  );
}

export function Route(props: { children: ReactNode }) {
  return (
    <OasisErrorBoundary>
      <Suspense fallback={null}>{props.children}</Suspense>
    </OasisErrorBoundary>
  );
}

function ProtectedRoute(props: { children: ReactNode }) {
  const $env = Oasis.Env.useStore();
  const $session = Oasis.Session.useStore();

  useEffect(() => {
    if ($session.status === 'UNAUTHENTICATED') {
      if ($env.isVr) {
        Oasis.NetworkCommands.emitSignedOut();
      } else {
        AppHistory.navigate('/login');
      }
    }
  }, [$session.status, $env.isVr]);

  return $session.status === 'UNAUTHENTICATED' ? null : <Route>{props.children}</Route>;
}

function DevModeRoute(props: { children: ReactNode }) {
  const $env = Oasis.Env.useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!$env.isDevMode) navigate(-1);
  }, [$env.isDevMode, navigate]);

  return <ProtectedRoute>{$env.isDevMode ? props.children : null}</ProtectedRoute>;
}
